import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { useUIContext } from 'context/UIProvider'
import React from 'react'
import styles from './Wishlist.module.css'

interface WishlistProps {
  // Define props here
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type WishItems = {
  [key: string]: {
    title: string
    description: string
  }
}

const Wishlist: React.FC<WishlistProps> = (props) => {
  // Component logic here
  const { wishItem, setWishItem } = useUIContext()

  const wishItems: WishItems = {
    null: {
      title: 'none',
      description: 'none',
    },
    export: {
      title: 'Export',
      description: 'Export as html, pdf, etc.',
    },
    lightMode: {
      title: 'Light Mode',
      description: 'Switch to light mode with a bright theme',
    },
    suggest: {
      title: 'More Content Generation Approaches',
      description:
        'Suggest alternative versions, refine copy, More ways to improve writings...',
    },
    help: {
      title: 'Smart assistant',
      description: 'Get help with the app',
    },
    saveAs: {
        title: 'File management',
        description: 'Tag, delete, replace files',
      },
  }

  const handleClose = () => {
    setWishItem('')
  }

  return (
    <Dialog
      open={wishItem ? true : false}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <b>{wishItems[`${wishItem || 'null'}`].title}</b>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <b>{wishItems[`${wishItem || 'null'}`].description}</b>
          <br />
          <br />
          This is not available for testers yet. Request to get access 😊
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          className={styles.discard}
        >
          OK
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          className={styles.submit}
        >
          I want it
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Wishlist
