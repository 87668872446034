import FullScreenLayout from 'components/FullscreenLayout'
import { useDataContext } from '../context/DataProvider'
// import styles from "./Home.module.css";
import TopicEditor from 'components/TopicEditor'
import AppMenu from 'components/AppMenu'
import FileMenu from 'components/FileMenu'
import { LoadingIndicatorFullScreen } from 'components/LoadingIndicator'
import { Fade } from '@mui/material'
import { useUIContext } from 'context/UIProvider'
import Wishlist from 'components/Wishlist'

export default function Home() {
  const { topicDoc } = useDataContext()
  const { isPageLoading } = useUIContext()

  return (
    <FullScreenLayout>
      <AppMenu />
      {topicDoc && <TopicEditor />}
      {/* <div className={`${styles.container} ${styles.highlight}`}>Home</div> */}
      <FileMenu />

      <LoadingIndicatorFullScreen />
      <Wishlist/>
    </FullScreenLayout>
  )
}
