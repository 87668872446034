import { DataProvider, useDataContext } from './context/DataProvider'
import { AxiosClient } from './apis/configs/AxiosClient'
import { UIProvider } from './context/UIProvider'
import { Preloader } from './context/Preloader'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Home from './pages/Home'
import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AppRoutes = () => {
  const { step } = useDataContext()
  return (
    <>
      {/* <Timer initialSeconds={assessment?.setup.timeLimit ?? 0} /> */}
      <Routes>
        {/* <Route path="/support" element={<Support />} /> */}
        <Route
          path="/*"
          element={
            <>
              {/* {step === 0 && <Home />} */}
              <Home />
            </>
          }
        />
      </Routes>
    </>
  )
}
function App() {
  return (
    <BrowserRouter>
      <AxiosClient>
        <DataProvider>
          <UIProvider>
            <Preloader>
              <div className="App">
                <ToastContainer limit={99} />
                <AppRoutes />
              </div>
            </Preloader>
          </UIProvider>
        </DataProvider>
      </AxiosClient>
    </BrowserRouter>
  )
}

export default App
