import { createContext, useContext, useState, ReactNode } from 'react'

interface UIContextProps {
  isPageLoading: boolean
  setIsPageLoading: (isLoading: boolean) => void
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  wishItem: string
  setWishItem: (item: string) => void
}

const UIContext = createContext<UIContextProps | null>(null)

export const useUIContext = (): UIContextProps => {
  const uiContext = useContext(UIContext)
  if (!uiContext) {
    throw new Error('UI context must be inside a provider')
  }
  return uiContext
}

type Props = {
  children: ReactNode
}
export const UIProvider = ({ children }: Props) => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [wishItem, setWishItem] = useState<string>('')

  const providerValue = {
    isPageLoading,
    setIsPageLoading,
    isModalOpen,
    setIsModalOpen,
    wishItem,
    setWishItem,
  }

  return (
    <UIContext.Provider value={providerValue}>{children}</UIContext.Provider>
  )
}
