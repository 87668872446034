import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import { useDataContext } from '../context/DataProvider'
import styles from './TopicEditor.module.css'
import { Button, Fade, Grow, TextField, Typography } from '@mui/material'
import { Block, LearningOutcome } from 'apis/entities/topic.entity'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'

//animations
import { TransitionGroup } from 'react-transition-group'
import Collapse from '@mui/material/Collapse'
import { API } from 'apis/API'
import { useUIContext } from 'context/UIProvider'
import { styled } from '@mui/material/styles'
import { Tooltip } from 'react-tooltip'
import ReactDOMServer from 'react-dom/server'

export default function TopicEditor() {
  const { step, topicDoc, setTopicDoc } = useDataContext()
  const {
    isModalOpen,
    isPageLoading,
    setIsModalOpen,
    setIsPageLoading,
    setWishItem,
  } = useUIContext()
  const [outcomeFocus, setOutcomeFocus] = useState(0)
  const sectionFocus = useRef(0)
  const [sectionIdleTimerId, setSectionIdleTimerId] =
    useState<NodeJS.Timeout | null>(null)
  const outcomeRef = useRef<HTMLInputElement>(null) //for auto focus
  enum Actions {
    NONE,
    ADD_OUTCOME,
    ADD_SECTION,
    // Add more actions as needed
  }
  const [lastAction, setLastAction] = useState(Actions.NONE)
  const [tooltipStates, setTooltipStates] = useState([1, 1, 0, 0, 0, 0]) //first one is master
  const tooltipElementRef = useRef(null)
  //   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
  //     setText(event.target.value);
  //     event.target.style.height = 'auto';
  //     event.target.style.height = `${event.target.scrollHeight}px`;
  //   };

  const handleClickAddOutcome = () => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.learningOutcomes.push({ title: '' })
    setTopicDoc(newTopicDoc)
    setLastAction(Actions.ADD_OUTCOME)
  }

  const handleClickSuggestLearningOutcomes = () => {
    setWishItem('suggest')
  }
  const handleClickSuggestNotes = async () => {
    console.log('handleClickSuggestNotes')
    setIsPageLoading(true)
    const { name, notes, learningOutcomes } = topicDoc!
    const result = await API.topicToGuides({
      name,
      notes,
      learningOutcomes,
    })
    setIsPageLoading(false)

    // use filter to get the first object in the array that has type === 'introduction'
    const introNotes = result?.find((guide) => guide.type === 'introduction')
      ?.notes
    const contentNotes = result
      ?.filter((guide) => guide.type === 'main')
      .map((guide) => guide.notes)
    const conclusionNotes = result?.find((guide) => guide.type === 'conclusion')
      ?.notes

    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.introduction!.notes = introNotes

    const newSections = contentNotes?.map((notes) => {
      return {
        body: '',
        notes,
      }
    })
    newTopicDoc.sections = newSections
    newTopicDoc.conclusion!.notes = conclusionNotes
    setTopicDoc(newTopicDoc)
    // console.log('topicToGuides:', result)
  }

  const isAllowedToAddSection = (): boolean => {
    const lastSection = topicDoc!.sections?.[topicDoc!.sections.length - 1]
    if (lastSection?.body?.trim() || lastSection?.notes?.trim()) {
      return true
    }
    return false
  }
  const handleClickAddSection = () => {
    if (!isAllowedToAddSection()) return
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.sections?.push({
      _id: '',
      body: '',
    })
    setTopicDoc(newTopicDoc)
    setLastAction(Actions.ADD_SECTION)
  }

  // //useEffect
  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const result = await API.getAllTopicDocs();
  //       console.log('result', result);
  //       // Do something with the result if needed
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData(); // Call the async function immediately

  // }, [])

  useEffect(() => {
    // This effect will run whenever myState changes
    if (outcomeFocus > 0) return
    const timerId = setTimeout(() => {
      const newTopicDoc = { ...topicDoc! }
      const filteredLearningOutcomes = newTopicDoc.learningOutcomes.filter(
        (learningOutcome) => learningOutcome.title.trim() !== '',
      )
      newTopicDoc.learningOutcomes =
        filteredLearningOutcomes.length === 0
          ? [{ title: '' }]
          : filteredLearningOutcomes
      setTopicDoc(newTopicDoc)
    }, 200) // Adjust the time (in milliseconds) according to your needs

    // Clean up the timer when the component unmounts or when myState changes again
    return () => clearTimeout(timerId)
  }, [outcomeFocus, setTopicDoc, topicDoc])

  useEffect(() => {
    // Focus on the first input element after rendering
    if (lastAction === Actions.ADD_OUTCOME) {
      outcomeRef.current?.focus()
      setLastAction(Actions.NONE)
      return
    }
  }, [lastAction])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.name = event.target.value
    setTopicDoc(newTopicDoc)
  }

  const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.notes = event.target.value
    setTopicDoc(newTopicDoc)
  }

  //rendering
  const renderTopicMeta = (text: string, notes?: string) => {
    return (
      <>
        <div></div>
        <div></div>
      </>
    )
  }

  const renderLearningOutcome = (
    learningOutcome: LearningOutcome,
    index: number,
  ) => {
    const handleBodyChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.learningOutcomes[index].title = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleFocusLost = () => {
      console.log('focus lost')
      setOutcomeFocus(0)
    }
    const handleFocus = () => {
      console.log('focus')
      setOutcomeFocus(1)
    }

    return (
      // <div className={styles.grid}>
      <div className={styles.outcomecontainer}>
        <input
          className={styles.input}
          onChange={handleBodyChange}
          value={learningOutcome.title}
          onBlur={handleFocusLost}
          onFocus={handleFocus}
          ref={
            index === topicDoc!.learningOutcomes.length - 1 ? outcomeRef : null
          }
          placeholder="Identify/understand/describe/explain ..."
          data-tooltip-id={index === 0 ? 'tooltip-2' : ''}
        />
      </div>
      // <div></div>
      // <div></div>
      // </div>
    )
  }

  const renderIntroduction = (block: Block) => {
    const handleBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.introduction!.body = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.introduction!.notes = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleClickGenerateIntroduction = async () => {
      setIsPageLoading(true)
      const result = await API.topicAndGuideToContent({
        topicDoc: topicDoc!,
        generateType: 'introduction',
        generateIndex: -1,
      })
      const newNote = result
      console.log('newNote', newNote)
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.introduction!.body = newNote
      setTopicDoc(newTopicDoc)

      setIsPageLoading(false)
    }

    return (
      <>
        <TextareaAutosize
          minRows={2}
          className={styles.textarea}
          onChange={handleBodyChange}
          value={block.body}
        />
        <div>
          <Button
            className={`${styles.button} ${styles.aibutton}`}
            onClick={handleClickGenerateIntroduction}
          >
            {'⬅ Generate'}
          </Button>
        </div>
        <div>
          <TextareaAutosize
            placeholder="Notes to generate content"
            minRows={2}
            className={styles.textarea}
            onChange={handleNotesChange}
            value={block.notes}
          />
        </div>
      </>
    )
  }

  const renderSection = (section: Block, index: number) => {
    const handleBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      if (newTopicDoc.sections && newTopicDoc.sections[index]) {
        newTopicDoc.sections[index].body = event.target.value
      }
      setTopicDoc(newTopicDoc)
    }
    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      if (newTopicDoc.sections && newTopicDoc.sections[index]) {
        newTopicDoc.sections[index].notes = event.target.value
      }
      setTopicDoc(newTopicDoc)
    }

    const handleFocus = () => {
      console.log('focus')
      sectionFocus.current = 1
      if (sectionIdleTimerId) {
        clearTimeout(sectionIdleTimerId)
        setSectionIdleTimerId(null)
      }
    }

    const handleFocusLost = () => {
      console.log('focus lost')
      if ((topicDoc!.sections?.length ?? 0) > 1) {
        sectionFocus.current = 0

        const timerId = setTimeout(() => {
          console.log('sectionFocus.current', sectionFocus.current)
          const testIndex = topicDoc!.sections!.length - 1
          if (sectionFocus.current === 1) return
          if (
            !topicDoc!.sections![testIndex].body?.trim() &&
            !topicDoc!.sections![testIndex].notes?.trim()
          ) {
            const newTopicDoc = { ...topicDoc! }
            newTopicDoc.sections!.splice(testIndex, 1)
            setTopicDoc(newTopicDoc)
          }
        }, 1000)
        setSectionIdleTimerId(timerId)
      }
    }

    const handleClickGenerateBody = async () => {
      setIsPageLoading(true)
      const result = await API.topicAndGuideToContent({
        topicDoc: topicDoc!,
        generateType: 'main',
        generateIndex: index,
      })
      const newNote = result
      console.log('newNote', newNote)
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.sections![index].body = newNote
      setTopicDoc(newTopicDoc)

      setIsPageLoading(false)
    }

    return (
      <div className={styles.grid}>
        <div>
          <TextareaAutosize
            minRows={2}
            className={styles.textarea}
            onChange={handleBodyChange}
            value={section.body}
            onFocus={handleFocus}
            onBlur={handleFocusLost}
          />
        </div>
        <div>
          <Button
            className={`${styles.button} ${styles.aibutton}`}
            onClick={handleClickGenerateBody}
            disabled={section.notes?.trim() === '' || !section.notes}
            data-tooltip-id={index === 0 ? 'tooltip-5' : ''}
          >
            {'⬅ Generate'}
          </Button>
        </div>
        <div>
          <TextareaAutosize
            placeholder="Notes to generate content"
            minRows={2}
            className={styles.textarea}
            onChange={handleNotesChange}
            value={section.notes}
            onFocus={handleFocus}
            onBlur={handleFocusLost}
          />
        </div>
      </div>
    )
  }

  const renderConclusion = (block: Block) => {
    const handleBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.conclusion!.body = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.conclusion!.notes = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleClickGenerateConclusion = async () => {
      setIsPageLoading(true)
      const result = await API.topicAndGuideToContent({
        topicDoc: topicDoc!,
        generateType: 'conclusion',
        generateIndex: -1,
      })
      const newNote = result
      console.log('newNote', newNote)
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.conclusion!.body = newNote
      setTopicDoc(newTopicDoc)

      setIsPageLoading(false)
    }

    return (
      <>
        <TextareaAutosize
          minRows={2}
          className={styles.textarea}
          onChange={handleBodyChange}
          value={block.body}
        />
        <div>
          <Button
            className={`${styles.button} ${styles.aibutton}`}
            onClick={handleClickGenerateConclusion}
          >
            {'⬅ Generate'}
          </Button>
        </div>
        <div>
          <TextareaAutosize
            placeholder="Notes to generate content"
            minRows={2}
            className={styles.textarea}
            onChange={handleNotesChange}
            value={block.notes}
          />
        </div>
      </>
    )
  }
  const handleClickTooltip = (index: number, scrollTarget?: string) => {
    console.log('handleClickTooltip')
    const newTooltipStates = [...tooltipStates]

    //switch off
    newTooltipStates[index] = 0

    //switch on next
    let nextIndex = index + 1 < tooltipStates.length ? index + 1 : 0
    if (nextIndex) {
      newTooltipStates[nextIndex] = 1
    }
    const element = document.querySelector(
      `[data-tooltip-id="tooltip-${nextIndex}"]`,
    )
    if (element) {
      if (scrollTarget !== undefined) {
        console.log('scrollTarget', scrollTarget)
        const target = document.getElementById(scrollTarget)
        target?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // window.scrollTo({ behavior: 'smooth', top: scrollPosition })
      } else {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      // element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' })
      // window.scrollTo({ behavior: 'smooth', top: 0 })
    }
    setTooltipStates(newTooltipStates)
  }

  return (
    <div className={`${styles.background}`} id="scroll-container">
      <div className={styles.scrollcontainer}>
        <Grow
          in={topicDoc !== undefined}
          style={{ transformOrigin: '50% 50vh 0' }}
        >
          <div className={`${styles.pagecontainer}`}>
            <div>
              <div className={styles.topgrid}>
                <div className={styles.topgridcolumn1}>
                  <span className={styles.fieldlabel}>Topic Name</span>
                  <div>
                    <input
                      placeholder="Noun or phrase < 10 words"
                      className={styles.input}
                      onChange={handleInputChange}
                      value={topicDoc!.name}
                      data-tooltip-id="tooltip-1"
                      // data-event={'click'}
                    />
                    <Tooltip
                      clickable={true}
                      place="right"
                      // data-tooltip-id="tooltip-1"
                      id="tooltip-1"
                      isOpen={tooltipStates[0] === 1 && tooltipStates[1] === 1}
                      className={styles.tooltip}
                      border={'1px solid rgb(186, 97, 255)'}
                      // border cannot be set in css
                      // openOnClick={true}
                    >
                      <div
                        className={styles.tooltipcontainer}
                        onClick={() => handleClickTooltip(1)}
                      >
                        <h3>Step 1: Input the topic name</h3>
                        <li>This cannot be empty</li>
                      </div>
                    </Tooltip>
                  </div>
                  <div className={styles.centercontainer}>
                    <Button
                      className={`${styles.button} ${styles.aibutton}`}
                      onClick={handleClickSuggestLearningOutcomes}
                    >
                      {'Suggest learning outcomes ⬇'}
                    </Button>
                  </div>
                  <span className={styles.fieldlabel}>Learning outcomes</span>

                  <TransitionGroup>
                    {topicDoc!.learningOutcomes.map(
                      (learningOutcome, index) => {
                        return (
                          <Collapse key={`learningoutcome-${index}`}>
                            {renderLearningOutcome(learningOutcome, index)}
                          </Collapse>
                        )
                      },
                    )}
                  </TransitionGroup>
                  <Tooltip
                    clickable={true}
                    place="right"
                    // data-tooltip-id="tooltip-2"
                    id="tooltip-2"
                    isOpen={tooltipStates[0] === 1 && tooltipStates[2] === 1}
                    className={styles.tooltip}
                    border={'1px solid rgb(186, 97, 255)'}
                    // border cannot be set in css
                    // openOnClick={true}
                  >
                    <div
                      className={styles.tooltipcontainer}
                      onClick={() => handleClickTooltip(2)}
                    >
                      <h3>Step 2: Input the learning outcomes</h3>
                      <li>Add more learning outcomes if necessary</li>
                      {/* </ul> */}
                    </div>
                  </Tooltip>
                  <div className={styles.centercontainer}>
                    <Button
                      className={styles.button}
                      onClick={() => {
                        handleClickAddOutcome()
                      }}
                    >
                      Add a learning outcome
                    </Button>
                  </div>
                  {/* {renderTopicMeta(topicDoc!.name, topicDoc!.notes)} */}

                  <div />
                  <div></div>
                </div>
                <div></div>
                <div className={styles.topgridcolumn3}>
                  <span className={styles.fieldlabel} id="notes-label">
                    Notes
                  </span>
                  <Tooltip
                    clickable={true}
                    place="left"
                    // data-tooltip-id="tooltip-2"
                    id="tooltip-3"
                    isOpen={tooltipStates[0] === 1 && tooltipStates[3] === 1}
                    className={styles.tooltip}
                    border={'1px solid rgb(186, 97, 255)'}
                    // border cannot be set in css
                    // openOnClick={true}
                  >
                    <div
                      className={styles.tooltipcontainer}
                      onClick={() => handleClickTooltip(3)}
                    >
                      <h3>Step 3: Input some notes about the topic</h3>
                      <b>
                        <li>
                          These points will be used to generate outlines for the
                          sections
                        </li>
                      </b>
                      <li>
                        If you don't have any notes, you can skip this step
                      </li>
                      {/* </ul> */}
                    </div>
                  </Tooltip>
                  <TextareaAutosize
                    placeholder="Notes about the topic"
                    minRows={2}
                    className={styles.textarea}
                    onChange={handleNotesChange}
                    value={topicDoc!.notes}
                    data-tooltip-id="tooltip-3"
                  />

                  <Button
                    className={styles.aibutton}
                    onClick={() => {
                      handleClickSuggestNotes()
                    }}
                    data-tooltip-id="tooltip-4"
                  >
                    {'Suggest notes for the content ⬇'}
                  </Button>
                  <Tooltip
                    clickable={true}
                    place="left"
                    // data-tooltip-id="tooltip-2"
                    id="tooltip-4"
                    isOpen={tooltipStates[0] === 1 && tooltipStates[4] === 1}
                    className={styles.tooltip}
                    border={'1px solid rgb(186, 97, 255)'}
                    // border cannot be set in css
                    // openOnClick={true}
                  >
                    <div
                      className={styles.tooltipcontainer}
                      onClick={() => handleClickTooltip(4)}
                    >
                      <h3>
                        Step 4: Generate section outlines for sections in the
                        topic
                      </h3>
                      <h3>
                        Please be aware that executing this action will
                        eliminate all sections below:
                      </h3>
                      <b>
                        <li>introduction</li>
                        <li>All section content</li>
                        <li>Conclusion</li>
                      </b>
                      {/* </ul> */}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>

            {topicDoc!.introduction && (
              <div>
                <div className={styles.fieldlabel}>Introduction</div>
                <div className={styles.grid}>
                  {renderIntroduction(topicDoc!.introduction)}
                </div>
              </div>
            )}

            {topicDoc!.sections && (
              <div>
                <div className={styles.fieldlabel}>Content</div>
                <TransitionGroup>
                  {topicDoc!.sections?.map((section, index) => {
                    return (
                      <Collapse key={`section-${index}`}>
                        {renderSection(section, index)}
                      </Collapse>
                    )
                  })}
                </TransitionGroup>
                <Tooltip
                  clickable={true}
                  place="top"
                  // data-tooltip-id="tooltip-2"
                  id="tooltip-5"
                  isOpen={tooltipStates[0] === 1 && tooltipStates[5] === 1}
                  className={styles.tooltip}
                  border={'1px solid rgb(186, 97, 255)'}
                  // border cannot be set in css
                  // openOnClick={true}
                >
                  <div
                    className={styles.tooltipcontainer}
                    onClick={() => handleClickTooltip(5)}
                  >
                    <h3>Step 5: Generate section content from notes</h3>
                    <b>
                      <li>This will overwrite the content on the left</li>
                    </b>
                    <li>The notes cannot be empty</li>
                    {/* </ul> */}
                  </div>
                </Tooltip>
                <div className={styles.grid}>
                  <div>
                    <Button
                      className={styles.button}
                      onClick={() => {
                        handleClickAddSection()
                      }}
                      disabled={!isAllowedToAddSection()}
                    >
                      add a section
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {topicDoc!.conclusion && (
              <div>
                <div className={styles.fieldlabel}>Conclusion</div>
                <div className={styles.grid}>
                  {renderConclusion(topicDoc!.conclusion)}
                </div>
              </div>
            )}
          </div>
        </Grow>
      </div>
    </div>
  )
}
