// MenuListComposition.jsx
import * as React from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Stack from '@mui/material/Stack'
import styles from './AppMenu.module.css'
import Divider from '@mui/material/Divider'
import { FileMenuMode, useDataContext } from 'context/DataProvider'
import { API } from 'apis/API'
import { useEffect } from 'react'
import { toastSuccess } from 'utils/toast'
import { useUIContext } from 'context/UIProvider'
import DoneIcon from '@mui/icons-material/Done';
import { Icon } from '@mui/material'

export default function MenuListComposition() {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const anchorRef2 = React.useRef<HTMLButtonElement>(null)
  const [open2, setOpen2] = React.useState(false)

  const { topicDoc, setIsFileMenuOpen, setFileMenuMode } = useDataContext()
  const { setWishItem } = useUIContext()
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleToggle2 = () => {
    setOpen2((prevOpen) => !prevOpen)
  }

  const handleLog = () => {
    console.log('topicDoc', topicDoc)
  }

  //open load modal
  const handleClickOpen = () => {
    setFileMenuMode(FileMenuMode.load)
    setIsFileMenuOpen(true)
    setOpen(false)
  }

  const handleClickNew = () => {
    setFileMenuMode(FileMenuMode.new)
    setIsFileMenuOpen(true)
    setOpen(false)
  }

  const handleClickHelp = () => {
    setWishItem('help')
  }

  
  
  const handleClickSave = async () => {
    triggerSave()
  }

  const handleClickSaveAs = async () => {
    setWishItem('saveAs')
  }

  const triggerSave = async () => {
    if (!topicDoc) {
      return
    }
    const result = await API.updateTopic(topicDoc)
    console.log('handleClickSave', result)
    if (result) {
      toastSuccess('Document saved', 1000)
    }
    setOpen(false)
    return result
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
    
  }
  const handleClose2 = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef2.current &&
      anchorRef2.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen2(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the Cmd key (Mac) or Ctrl key (Windows) is pressed
      const isCmdOrCtrlPressed = (event.metaKey || event.ctrlKey);

      // Check if the "S" key is pressed
      const isSPressed = (event.key === 's' || event.code === 'KeyS');

      // If Cmd/Ctrl + S is pressed, prevent the default browser behavior and trigger your custom action
      if (isCmdOrCtrlPressed && isSPressed) {
        event.preventDefault();
        // Call your custom save or any other action here
        console.log('Custom save action triggered!');
        triggerSave()
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [topicDoc]); // Empty dependency array ensures that the effect runs once when the component mounts


  function handleClickExport(): void {
    setWishItem('export')
    setOpen(false)
  }

  const handleClickLightMode = () => {
    setWishItem('lightMode')
    setOpen2(false)
  }

  return (
    <div className={styles.container}>
      <Stack direction="row" spacing={2}>
        <div>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={`${styles.button} ${open && styles.buttonActive}`}
          >
            File
          </Button>
          <Button
            ref={anchorRef2}
            id="view-button"
            aria-controls={open2 ? 'view-menu' : undefined}
            aria-expanded={open2 ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle2}
            className={`${styles.button} ${open2 && styles.buttonActive}`}
          >
            View
          </Button>
          <Button
            // ref={anchorRef2}
            // id="view-button"
            // aria-controls={open2 ? 'view-menu' : undefined}
            // aria-expanded={open2 ? 'true' : undefined}
            aria-haspopup="true"
            // onClick={handleToggle2}
            className={`${styles.button} ${open2 && styles.buttonActive}`}
            onClick={handleClickHelp}
          >
            Help
          </Button>
          <Popper
            open={open2}
            anchorEl={anchorRef2.current}
            role={undefined}
            placement="bottom-start"
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper className={styles.popover}>
                  <ClickAwayListener onClickAway={handleClose2}>
                    <MenuList
                      autoFocusItem={open2}
                      id="view-menu"
                      aria-labelledby="view-button"
                      onKeyDown={handleListKeyDown}
                      className={styles.menuList}
                    >
                      <MenuItem className={styles.item} onClick={handleClickLightMode}>
                        <Icon></Icon>Light Mode
                      </MenuItem>
                      <MenuItem className={styles.item} onClick={()=>{}}>
                       <DoneIcon/>Dark Mode
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper className={styles.popover}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem className={styles.item} onClick={handleClickNew}>
                        New Topic
                      </MenuItem>
                      <MenuItem className={styles.item} onClick={handleClickOpen}>
                        Open...
                      </MenuItem>
                      <MenuItem className={styles.item} onClick={handleClickSave}>
                        Save
                      </MenuItem>
                      <MenuItem className={styles.item} onClick={handleClickSaveAs}>
                        Save a Copy
                      </MenuItem>
                      <MenuItem className={styles.item} onClick={handleClickExport}>
                        Export
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
    </div>
  )
}
