import { createContext, useContext, useState, ReactNode, useRef } from 'react'

import { isProduction } from '../utils/EnvUtils'
import { TopicDoc } from 'apis/entities/topic.entity'
import { API } from 'apis/API'

interface DataContextProps {
  step: number
  setStep: (step: number) => void

  topicDoc: TopicDoc | undefined
  setTopicDoc: (topicDoc: TopicDoc | undefined) => void

  isFileMenuOpen: boolean
  setIsFileMenuOpen: (isFileMenuOpen: boolean) => void

  loadTopicById: (topicId: string) => void

  fileMenuMode: FileMenuMode
  setFileMenuMode: (fileMenuMode: FileMenuMode) => void
  
  getNewTopicDoc: (filename:string) => TopicDoc
}

export enum FileMenuMode {
  closed,
  load,
  new,
}

const Context = createContext<DataContextProps | null>(null)

export const useDataContext = (): DataContextProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}

const defaultTopicDoc: TopicDoc = {
  _id: '',
  fileName: '',
  name: '',
  learningOutcomes: [{ title: '' }],
  introduction: { _id: '', body: '' },
  sections: [{ _id: '', notes: '', body: '' }],
  conclusion: {_id: '', body: '' },
}
export const DataProvider = ({ children }: Props) => {
  const [step, setStep] = useState(0)
  const [isFileMenuOpen, setIsFileMenuOpen] = useState(true)
  const [topicDoc, setTopicDoc] = useState<TopicDoc | undefined>()
  const [fileMenuMode, setFileMenuMode] = useState<FileMenuMode>(FileMenuMode.load)

  const loadTopicById = async (topicId: string) => {
    console.log('loadTopicById', topicId)
    const loadedTopicDoc = await API.getTopicById(topicId)
    if (loadedTopicDoc) {
      // const newTopicDoc = { ...defaultTopicDoc, ...topicDoc }
      setTopicDoc(loadedTopicDoc)
    }
  }

  const getNewTopicDoc = (fileName:string): TopicDoc => {
    const newTopicDoc = { ...defaultTopicDoc, fileName: fileName, name: fileName }
    return newTopicDoc
  }

  const providerValue = {
    step,
    setStep,
    topicDoc,
    setTopicDoc,
    isFileMenuOpen,
    setIsFileMenuOpen,
    loadTopicById,
    fileMenuMode,
    setFileMenuMode,
    getNewTopicDoc
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
