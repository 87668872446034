import apiClient from './configs/AxiosClient'
import { BlockGuide, CreateTopicDto, TopicAndGuideToContentDTO, TopicDoc, TopicToGuidesDTO } from './entities/topic.entity'
import { ActivityLogRequest } from './requests/activity-log.request'
import { AnswerRequestV2 } from './requests/answer-v2.request'
import { AnswerRequest } from './requests/answer.request'

export const TEST_CANDIDATE_ID = 'ac469c81-0470-407b-9536-446da9b43991'

export const API = {
  getAllTopicDocs: async (): Promise<TopicDoc[] | undefined> => {
    let url = `/content/`
    const response: any = await apiClient.get(url)
    return response.data
  },
  getTopicById: async (topicId:string): Promise<TopicDoc | undefined> => {
    let url = `/content/${topicId}`
    const response: any = await apiClient.get(url)
    return response.data
  },
  createTopic: async (createTopicDto: CreateTopicDto): Promise<TopicDoc | undefined> => {
    let url = `/content/`
    const response: any = await apiClient.post(url, createTopicDto)
    return response.data
  },
  updateTopic: async (topicDoc: TopicDoc): Promise<TopicDoc | undefined> => {
    let url = `/content/${topicDoc._id}`
    const response: any = await apiClient.put(url, topicDoc)
    return response.data
  },
  
  topicToGuides: async (dto: TopicToGuidesDTO): Promise<BlockGuide[] | undefined> => {
    let url = `/prompt/notes`
    const response: any = await apiClient.post(url, dto)
    return response.data
  },

  topicAndGuideToContent: async (dto: TopicAndGuideToContentDTO): Promise<string | undefined> => {
    let url = `/prompt/content`
    const response: any = await apiClient.post(url, dto)
    return response.data
  },
  // getAssessment: async (
  //   candidateId: string,
  // ): Promise<Assessment | Assessment2 | undefined> => {
  //   let url = `/candidates/${candidateId}/assessment`
  //   if (process.env.REACT_APP_CI === 'true') {
  //     url = `/candidates/${TEST_CANDIDATE_ID}/assessment?limit=60&ci=1`
  //   }
  //   const response: any = await apiClient.get(url)
  //   return response.data
  // },

  // postAnswer: async (request: AnswerRequest): Promise<any> => {
  //   const response: any = apiClient.post('/answers', request)
  //   return response.data
  // },
}
